import React from "react";
import ButtonAppbar from '../../components/header/ButtonAppbar.component';
import Taskbox from '../../components/contents/taskBox/Taskbox.component';
import "./Clipboard.styles.css";

export default function Clipboard(){


    return(

        <div className="home">
        <div className="header">
        <ButtonAppbar />
        </div>
        <div className="content">
          <div className="clipitems">
           <Taskbox />
          </div>
         
        </div>
        
      </div>

      

    )

}