import React from "react";
import { useReducer, useEffect } from "react";
import { Check, X } from "react-feather";

function CreateClip(props) {
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { website: "", url: "" }
  );

  useEffect(() => {
    setUserInput({ website: props.website, url: props.url });
  },[props.website,props.url]);

  const handleChange = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setUserInput({ [name]: newValue });
  };

  return (
    <div className="newtaskbox">
      <div className="taskbox-items" id="box">
        <div className="text-items mr-auto">
          <div className="title">
            <input
              id="website-input"
              type="text"
              placeholder="website"
              name="website"
              value={userInput.website}
              onChange={(e) => handleChange(e)}
            ></input>
          </div>
          <div className="sub-title">
            <input
              id="url-input"
              type="text"
              placeholder="website url"
              name="url"
              value={userInput.url}
              onChange={(e) => handleChange(e)}
            ></input>
          </div>
        </div>

        <div className="save-icon">
          <Check
            onClick={() =>
              props.handleSave(props.checkid, userInput.website, userInput.url)
            }
            id="save"
          />
        </div>
        <div className="close-icon">
          <X onClick={() => props.handleClose()} id="close" />
        </div>
      </div>
    </div>
  );
}

export default CreateClip;
