import React, { useEffect }  from "react";
import useLocalStorage from "../../hooks/useLocalStorage";
import "./Taskbox.styles.css";
import { Button } from "@material-ui/core";
import { Clipboard, Trash2, Edit } from "react-feather";
import { useClipboard } from 'use-clipboard-copy';
import { useState, useReducer } from "react";
import CreateClip from "./../CreateClip";


const initialvalues = [];

const Taskbox = () => {
  const [tasks, setTasks] = useLocalStorage("clipboard", initialvalues);
  const [revItems,setrevItems]=useState([])
  const [clip, setclip] = useState(false);
  const [active, setActive] = useState("");
  const clipboard = useClipboard({
    onSuccess() {
      console.log('Text was copied successfully!')
    },
    onError() {
      console.log('Failed to copy text!')
    }
  });

  useEffect(()=>{

    const reversedItems = tasks.map(item => item).reverse();
    setrevItems(reversedItems);
  },[tasks])

  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { id: "", website: "", url: "" }
  );

  const ClipOpen = () => {
    setUserInput({ website: "", url: "" });
    setActive("");
     setclip(true);
  };

  const handleClick = (e) => {
    if(e.currentTarget.getAttribute("item") === "box"){
     const taskid=e.currentTarget.getAttribute("id");
   const url = revItems[taskid].url;
   console.log(url);
   clipboard.copy(url);
    }
  
  };

  const handleSave = (idCheck, web, url) => {
    if (idCheck === "") {
      var len = tasks.length;
      var id = 0;
      if (len > 0) {
        id = tasks[len - 1].id + 1;
      } else {
        id += 1;
      }
      setTasks([...tasks, { id: id, website: web, url: url }]);
      aftersave();
    } else {
      setTasks(
        tasks.map((task) =>
          task.id === idCheck ? { ...task, website: web, url: url } : task
        )
      );
      aftersave();
    }
  };

  const aftersave = () => {
    setActive("");
    setclip(false);
  };

  const handleClose = () => {
    aftersave();
  };

  const handleEdit = (e, website, url) => {
    e.stopPropagation();
    if(!clip && active === ""){
    aftersave();
    const index = parseInt(e.currentTarget.getAttribute("id"));
    setActive(index);
    setUserInput({ website: website, url: url });
    }
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    const id = parseInt(e.currentTarget.getAttribute("id"));
    setTasks(tasks.filter((task) => task.id !== id));
  };

  return (
    <div>
      <div className="clipboard-add">
        <Button variant="outlined" className="btn-clipboard" onClick={ClipOpen}>
          Add To Clipboard
          <span>
            <Clipboard />
          </span>
        </Button>
      </div>
      {clip ? (
        <CreateClip
          checkid=""
          website={userInput.website}
          url={userInput.url}
          handleSave={handleSave}
          handleClose={handleClose}
        />
      ) : (
        ""
      )}

      {revItems.map((task, index) =>
        active !== task.id ? (
          <div className="taskbox" key={index}>
            <div className="taskbox-items" onClick={handleClick} item="box" id={index}>
              <div className="text-items mr-auto">
                <div className="title">
                  <h6>{task.website}</h6>
                </div>
                <div className="sub-title">
                  <h6>{task.url}</h6>
                </div>
              </div>

              <div
                className="edit-icon"
                item="edit"
                onClick={(e) => handleEdit(e, task.website, task.url)}
                id={task.id}
              >
                <Edit />
              </div>
              <div
                className="delete-icon"
                item="delete"
                onClick={(e) => handleDelete(e)}
                id={task.id}
              >
                <Trash2 />
              </div>
            </div>
          </div>
        ) : (
          <CreateClip
            key={index}
            checkid={task.id}
            website={userInput.website}
            url={userInput.url}
            handleSave={handleSave}
            handleClose={handleClose}
          />
        )
      )}
    </div>
  );
};

export default Taskbox;
