import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import "./Appheader.styles.css";
import { Clipboard } from "react-feather";


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color:"black"
  },
 
}));

export default function ButtonAppBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" style={{backgroundColor:"#F5F5F5",boxShadow:"none"}}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
          <Clipboard /> Clipboard 
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}