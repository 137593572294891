import React from 'react';
import Clipboard from './pages/clipboard/Clipboard.pages'


function App() {
  return (
    <div className="App">
     
     <Clipboard />

    </div>
  );
}

export default App;
